import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f5cdb6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bc-step-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LdButton = _resolveComponent("LdButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LdButton, {
      disabled: _ctx.$props.disabled,
      rounded: "",
      medium: "",
      blue: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('nextPage')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('common.continue')), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}