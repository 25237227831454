
import { defineComponent, watch, ref } from 'vue'

const calculateBarValue = (value: number, max: number): number => (value * 100) / max

export default defineComponent({
	props: {
		max: {
			type: Number,
			default: 0,
		},
		current: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const currentValue = ref(calculateBarValue(props.current, props.max))

		watch(
			() => props.current,
			(newValue) => {
				currentValue.value = calculateBarValue(newValue, props.max)
			}
		)

		return {
			currentValue,
		}
	},
})
