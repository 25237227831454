
import { defineComponent, PropType } from 'vue'

import LdButton from '@/components/LdButton/LdButton.vue'

export default defineComponent({
	components: {
		LdButton,
	},
	props: {
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	emits: ['nextPage'],
	setup() {},
})
