import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1de1bc22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bc-stepper" }
const _hoisted_2 = { class: "bc-stepper-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepperProgressBar = _resolveComponent("StepperProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_StepperProgressBar, {
        max: _ctx.maxSteps,
        current: _ctx.currentStep
      }, null, 8, ["max", "current"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}