
import { defineComponent } from 'vue'
import StepperProgressBar from './StepperProgressBar/StepperProgressBar.vue'

export default defineComponent({
	name: 'LdStepper',
	components: {
		StepperProgressBar,
	},
	props: {
		maxSteps: {
			type: Number,
			default: 0,
		},
		currentStep: {
			type: Number,
			default: 0,
		},
	},
})
