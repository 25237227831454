import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row mt-5" }
const _hoisted_3 = { class: "columns is-centered is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LdStepper = _resolveComponent("LdStepper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LdStepper, {
          class: "column is-11-mobile is-10-tablet",
          "max-steps": _ctx.steps.length,
          "current-step": _ctx.step + 1
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
              selectedRoute: _ctx.selectedRoute,
              onNextPage: _ctx.onNextPage,
              onSelectRoute: _ctx.onSelectRoute
            }, null, 8, ["selectedRoute", "onNextPage", "onSelectRoute"]))
          ]),
          _: 1
        }, 8, ["max-steps", "current-step"])
      ])
    ])
  ]))
}