
import { defineComponent } from 'vue'
import { ref } from 'vue'
import StepContainer from '@/components/LdStepper/StepContainer/StepContainer.vue'
import StepHeader from '@/components/LdStepper/StepHeader/StepHeader.vue'
import StepFooter from '@/components/LdStepper/StepFooter/StepFooter.vue'
import { useI18n } from 'vue-i18n'
import { Steps } from '@/views/connection/Connection.vue'
import { sendPageViewEvent } from '@/services/segmentTracking'

export enum gaTypes {
	UA = 'ua',
	GA4 = 'ga4',
}
export default defineComponent({
	components: {
		StepContainer,
		StepHeader,
		StepFooter,
	},
	emits: ['nextPage', 'selectRoute'],
	setup(props, { emit }) {
		const selectedGa = ref<gaTypes | ''>('')

		const onNextPage = async () => {
			emit('selectRoute', selectedGa.value)
			emit('nextPage', Steps.MEASUREMENT)
		}

		return {
			onNextPage,
			selectedGa,
			gaTypes,
		}
	},
	mounted() {
		const { t } = useI18n()

		sendPageViewEvent({
			locationTitle: t('connection.steps.gaSelection.title'),
			virtualUrl: 'ga-selection',
		})
	},
})
