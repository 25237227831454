
import { defineComponent, computed, defineAsyncComponent, ref } from 'vue'

import LdStepper from '@/components/LdStepper/LdStepper.vue'
import { useStore } from 'vuex'
import { Status } from '@/store/state'
import { gaTypes } from './steps/GaSelection.vue'

export enum Steps {
	GA_SELECTION,
	MEASUREMENT,
	API_SECRET,
	PROPERTY,
	NATIVE_GA,
	LOADING,
	SUCCESS,
}

const components = {
	[Steps.GA_SELECTION]: defineAsyncComponent(() => import('./steps/GaSelection.vue')),
	[Steps.MEASUREMENT]: defineAsyncComponent(() => import('./steps/Measurement.vue')),
	[Steps.API_SECRET]: defineAsyncComponent(() => import('./steps/ApiSecret.vue')),
	[Steps.PROPERTY]: defineAsyncComponent(() => import('./steps/Property.vue')),
	[Steps.NATIVE_GA]: defineAsyncComponent(() => import('./steps/NativeGA.vue')),
	[Steps.LOADING]: defineAsyncComponent(() => import('./steps/Loading.vue')),
	[Steps.SUCCESS]: defineAsyncComponent(() => import('./steps/Success.vue')),
}
const steps = [
	Steps.GA_SELECTION,
	Steps.MEASUREMENT,
	Steps.API_SECRET,
	Steps.PROPERTY,
	Steps.NATIVE_GA,
	Steps.LOADING,
	Steps.SUCCESS,
]

export default defineComponent({
	name: 'Connection',
	components: {
		LdStepper,
	},
	emits: ['nextPage'],
	setup() {
		const store = useStore()
		const step = ref<Steps>(Steps.GA_SELECTION)
		let selectedRoute = ref<gaTypes>(gaTypes.GA4)
		const onSelectRoute = (route: gaTypes) => (selectedRoute.value = route)
		const { value: userData } = computed(() => store.getters['user/me/getState'])

		if (userData?.state === Status.LOADED && userData?.data?.store?.setupComplete) {
			step.value = Steps.SUCCESS
		}

		const component = computed(() => components[steps[step.value]])
		const onNextPage = (stepName: Steps): void => {
			step.value = stepName
		}

		return {
			steps,
			component,
			onNextPage,
			selectedRoute,
			onSelectRoute,
			step,
		}
	},
})
