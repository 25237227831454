
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
	name: 'LdButton',
	inheritAttrs: false,
	props: {
		disabled: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		rounded: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		outlined: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		normal: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		medium: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		large: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		icon: {
			type: String as PropType<string>,
			default: '',
		},
		iconLeft: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		iconRight: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		uppercase: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		blue: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		green: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		grey: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		href: {
			type: String as PropType<string>,
			default: '',
		},
	},
	setup(props) {
		const classes = computed((): object => {
			return {
				// default to normal size if no size is specified
				normal: props.normal || [props.normal, props.medium].every((v) => v === false),
				medium: props.medium,
				outlined: props.outlined,
				rounded: props.rounded,
				'icon-left': props.iconLeft,
				'icon-right': props.iconRight,
				loading: props.loading,
				uppercase: props.uppercase,
				blue: props.blue,
				green: props.green,
				grey: props.grey,
			}
		})

		return {
			classes,
		}
	},
})
